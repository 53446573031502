import React from "react"
import Utils from "../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"
import Confirm from "../components/Shared/Newsletter/Confirm"
import {
  createOverlay,
  createErrorOverlay,
  createContentList,
  createEmptyContentList,
  createErrorContentList,
} from "../contentfunctions/confirm.js"

import Carousel1 from "../images/privacy/privacy-policy-min.jpeg"

const ConfirmIndexPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage(
    "en",
    "/confirm",
    location.state,
    location.search
  )
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(
          data.confirmContent.item.content
        )
        const img = {
          src: Carousel1,
          altText: contentMap.seo_title.value,
          caption: contentMap.seo_description.value,
        }
        return (
          <Confirm
            contentMap={contentMap}
            commonContentMap={commonContentMap}
            originPage="confirm"
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            breadCrumbs={["menu_home", "menu_confirm"]}
            location={location}
            createContentList={createContentList}
            createOverlay={createOverlay}
            createErrorOverlay={createErrorOverlay}
            img={img}
            createEmptyContentList={createEmptyContentList}
            createErrorContentList={createErrorContentList}
            keywords={Utils.GetKeywordsFromTags(data.confirmContent.item.tags)}
          />
        )
      }}
    />
  )
}

export default ConfirmIndexPage

const pageQuery = graphql`
  {
    confirmContent: confirmContentEngb {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentEngb {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
